export const DEFAULT_LANDSCAPE_IMAGE_WIDTH = 568
export const DEFAULT_LANDSCAPE_IMAGE_HEIGHT = 320
export const DEFAULT_LANDSCAPE_IMAGE_SIZE = `${DEFAULT_LANDSCAPE_IMAGE_WIDTH}x${DEFAULT_LANDSCAPE_IMAGE_HEIGHT}`

export const DEFAULT_PORTRAIT_IMAGE_WIDTH = 600
export const DEFAULT_PORTRAIT_IMAGE_HEIGHT = 920
export const DEFAULT_PORTRAIT_IMAGE_SIZE = `${DEFAULT_PORTRAIT_IMAGE_WIDTH}x${DEFAULT_PORTRAIT_IMAGE_HEIGHT}`

export const DEFAULT_WALLPAPER_IMAGE_WIDTH = 1920
export const DEFAULT_WALLPAPER_IMAGE_HEIGHT = 1080
export const DEFAULT_WALLPAPER_IMAGE_SIZE = `${DEFAULT_WALLPAPER_IMAGE_WIDTH}x${DEFAULT_WALLPAPER_IMAGE_HEIGHT}`

export const DEFAULT_AUTHOR_IMAGE_WIDTH = 500
export const DEFAULT_AUTHOR_IMAGE_HEIGHT = 720
export const DEFAULT_AUTHOR_IMAGE_SIZE = `${DEFAULT_AUTHOR_IMAGE_WIDTH}x${DEFAULT_AUTHOR_IMAGE_HEIGHT}`
