import { defaultTheme } from "./theme"
import {
  SET_USER,
  SET_USER_DATA,
  SET_USER_FAVORITES,
  SET_USER_INVENTORY,
  SET_USER_SUBSCRIPTION,
} from "./enums/DispatchTypes"

const initialState = {
  theme: defaultTheme,
  user: null,
  isLoadingUser: true,
  userInventory: [],
  userSubscription: null,
  userFavorites: [],
}

const reducer = (state, { type, value }) => {
  switch (type) {
    case SET_USER_INVENTORY:
      return { ...state, userInventory: value }
    case SET_USER:
      return { ...state, user: value }
    case SET_USER_SUBSCRIPTION:
      return { ...state, userSubscription: value }
    case SET_USER_FAVORITES:
      return { ...state, userFavorites: value }

    case SET_USER_DATA:
      if (!value) {
        return { ...state, isLoadingUser: false }
      }

      const {
        role,
        user_id,
        last_login,
        basic_profile,
        billing_profile,
        subscriptions,
        inventory_items,
        favorites,
      } = value

      return {
        ...state,
        user: {
          ...value,
          role,
          user_id,
          last_login,
          basic_profile,
          billing_profile,
          subscriptions,
        },
        userInventory: inventory_items,
        userSubscription: subscriptions[0],
        userFavorites: favorites,
        isLoadingUser: false,
      }
    default:
      return state
  }
}

export { reducer, initialState }
