import React, { useEffect, useState } from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import { Space, Spin } from "antd"

import "./SocialShareButtons.less"
import { useTranslation } from "react-i18next"

const SocialShareButtons = () => {
  const [currentLocation, setCurrentLocation] = useState("")
  useEffect(() => {
    setCurrentLocation(window.location.href)
  }, [])

  const { t } = useTranslation()

  return (
    <div className="social-button-wrapper">
      <Spin spinning={!currentLocation}>
        <h4 className="secondary">{t("label:shareOnSocial")}:</h4>
        <Space>
          <TwitterShareButton url={currentLocation}>
            <TwitterIcon />
          </TwitterShareButton>
          <WhatsappShareButton url={currentLocation}>
            <WhatsappIcon />
          </WhatsappShareButton>
          <FacebookShareButton url={currentLocation}>
            <FacebookIcon />
          </FacebookShareButton>
          <LinkedinShareButton url={currentLocation}>
            <LinkedinIcon />
          </LinkedinShareButton>
        </Space>
      </Spin>
    </div>
  )
}

export default SocialShareButtons
