import React, { useEffect, useState } from "react"
import { useGlobalValue } from "./useGlobalValue"
import useCommonQueries from "./useCommonQueries"

const useFavorite = (itemId, itemType) => {
  const [isFavorite, setIsFavorite] = useState()
  const [{ userFavorites }] = useGlobalValue()
  const { toggleUserFavorite } = useCommonQueries()

  useEffect(() => {
    if (userFavorites) {
      const favoriteItem = userFavorites.find(
        ({ item_id }) => item_id === itemId
      )
      if (favoriteItem) {
        setIsFavorite(favoriteItem.is_favorite)
      }
    }
  }, [userFavorites])

  const internalSetFavorite = async favorite => {
    setIsFavorite(favorite)
    await toggleUserFavorite({
      item_id: itemId,
      item_type: itemType,
      is_favorite: !isFavorite,
    })
  }

  return [isFavorite, internalSetFavorite]
}

export default useFavorite
