import React from "react"
import PropTypes from "prop-types"

import "./ContainerWithPadding.less"

const ContainerWithPadding = ({ children, align, size, ...otherProps }) => {
  return (
    <div className={`container-with-padding ${size} ${align} `} {...otherProps}>
      {children}
    </div>
  )
}

ContainerWithPadding.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(["start", "center", "end"]),
  size: PropTypes.oneOf([
    "small",
    "default",
    "large",
    "full",
    "none",
    undefined,
  ]),
}

ContainerWithPadding.defaultProps = {
  align: "start",
  size: "default",
}

export default ContainerWithPadding
