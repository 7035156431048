import React, { createContext, useContext, useReducer } from "react"

export const GlobalContext = createContext()

export const GlobalProvider = ({ children, reducer, initialState }) => {
  const _reducer = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={_reducer}>{children}</GlobalContext.Provider>
  )
}

export const useGlobalValue = () => useContext(GlobalContext)
