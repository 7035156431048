import { useEffect, useState } from "react"
import { throttle } from "lodash"

const getDeviceConfig = () => {
  if (typeof window === "undefined") {
    return "sm"
  }

  const width = window.innerWidth

  if (width < 320) {
    return "xs"
  } else if (width >= 320 && width < 720) {
    return "sm"
  } else if (width >= 720 && width < 1024) {
    return "md"
  } else if (width >= 1024 && width < 1440) {
    return "lg"
  } else if (width >= 1440 && width < 2560) {
    return "xl"
  } else if (width >= 2560) {
    return "xxl"
  }
}

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig())

  const calcInnerWidth = throttle(function () {
    setBrkPnt(getDeviceConfig())
  }, 200)

  useEffect(() => {
    calcInnerWidth()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", calcInnerWidth)
      return () => window.removeEventListener("resize", calcInnerWidth)
    }
  }, [])

  return brkPnt
}
export default useBreakpoint
