import React from "react"
import PropTypes from "prop-types"
import "./CourseHeader.less"
import { Col, Row, Skeleton, Space } from "antd"
import { defaultTheme } from "../theme"
import { AuthorList, Duration, Price, VideoCount } from "./Labels"
import _ from "lodash"
import {
  FavoriteButton,
  MoreInfoButton,
  PlayButton,
  PurchaseButton,
} from "./Buttons"
import { useImageColorDetector } from "../hooks/useImageColorDetector"

const CourseHeader = ({
  data,
  canPlay,
  canPurchase,
  loading,
  onPlay,
  onPurchase,
  isFavorite,
  onToggleFavorite,
  showFavoriteButton,
  onShowMoreInfo,
}) => {
  const {
    wallpaper,
    title,
    subtitle,
    short_description,
    header_image,
    videos,
    price,
    price_policy,
    currency,
    special_price,
  } = data

  const { isDark } = useImageColorDetector({
    url: wallpaper?.localFile?.childImageSharp.fixed.src,
  })

  const totalTime = videos.reduce((accumulator, { video }) => {
    return accumulator + (video?.duration || 0)
  }, 0)
  const totalVideo = videos.length
  const allAuthorList = videos.reduce((authorList, video) => {
    return [
      ...authorList,
      ...(video.author_rels?.map(item => item.author) || []),
    ]
  }, [])
  const authorList = _.uniqBy(allAuthorList, "id")

  return (
    <div
      className={"content-header course-header"}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%) , url(${
          wallpaper?.localFile?.childImageSharp.fixed.src ||
          defaultTheme.defaultPortrait
        }) , linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(61,61,61,1) 100%)`,
      }}
    >
      <div className={`content ${isDark ? "dark" : "light"}`}>
        <Row className="info-wrapper">
          <Col sm={24} md={18} lg={10}>
            <div className="header-logo-wrapper">
              <img
                className="header-logo"
                src={header_image?.localFile?.publicURL}
                alt={title}
              />
            </div>
            <div>
              <h1 className="title">{title}</h1>
            </div>
            <div>
              <h2 className="subtitle">{subtitle}</h2>
            </div>
            <div>
              <p className="description">{short_description}</p>
            </div>
            <Space className="action-options" direction="horizontal">
              <div>
                <Duration totalTime={totalTime} />
              </div>
              <div>
                <VideoCount count={totalVideo} />
              </div>
              <div>
                <AuthorList authorList={authorList} />
              </div>
              <div>
                <Price
                  price={price}
                  pricePolicy={price_policy}
                  specialPrice={special_price}
                  currency={currency}
                />
              </div>
            </Space>
            <div>
              {loading ? (
                <Skeleton active />
              ) : (
                <>
                  <Space direction="horizontal" align="start" wrap>
                    {canPlay && (
                      <PlayButton
                        onClick={() => {
                          onPlay && onPlay(data)
                        }}
                      />
                    )}
                    {canPurchase && (
                      <PurchaseButton
                        price={price}
                        pricePolicy={price_policy}
                        specialPrice={special_price}
                        onClick={() => {
                          onPurchase && onPurchase(data)
                        }}
                      />
                    )}
                    <MoreInfoButton
                      onClick={() => {
                        onShowMoreInfo && onShowMoreInfo(data)
                      }}
                    />
                    {showFavoriteButton && (
                      <FavoriteButton
                        onClick={() => {
                          onToggleFavorite && onToggleFavorite(data)
                        }}
                        isFavorite={isFavorite}
                      />
                    )}
                  </Space>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CourseHeader

CourseHeader.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  canPurchase: PropTypes.bool,
  canPlay: PropTypes.bool,
  onPurchase: PropTypes.func,
  onPlay: PropTypes.func,
  onShowMoreInfo: PropTypes.func,
  onToggleFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  showFavoriteButton: PropTypes.bool,
  progress: PropTypes.number,
}
