import React from "react"
import { Space } from "antd"
import { Link } from "gatsby"
import logoGiacomo from "../../static/assets/images/logo-giacomo-catalani-editore.png"
import logoAts from "../../static/assets/images/logo-ats.png"
import logoTvPlus from "../../static/assets/images/logo-tv-plus.png"
import SocialShareButtons from "./SocialShareButtons"
import "./HeaderExtra.less"

const HeaderExtra = ({ images }) => {
  return (
    <div className="header-extra-info-wrapper">
      <div className="sponsor-logo-wrapper">
        {images?.length > 0 ? (
          <Space>
            {images.map((image, index) => (
              <img key={index} src={image} alt="ATS" />
            ))}
          </Space>
        ) : (
          <Space>
            <Link to="/" target="_blank">
              <img src={logoAts} alt="ATS" />
            </Link>
            <Link to="/" target="_blank">
              <img src={logoGiacomo} alt="Giacomo Catalani Editore" />
            </Link>
            <Link to="/" target="_blank">
              <img src={logoTvPlus} alt="tvplus" />
            </Link>
          </Space>
        )}
      </div>
      <div className="social-wrapper">
        <SocialShareButtons />
      </div>
    </div>
  )
}

export default HeaderExtra
