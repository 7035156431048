import React, { useMemo } from "react"
import "./WebinarTile.less"
import AtsCard from "./AtsCard"
import { AuthorList } from "./Labels"
import { formatDate, getImageUrl, isSubscriptionValid } from "../utils"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { navigate } from "gatsby"
import { DEFAULT_LANDSCAPE_IMAGE_SIZE } from "../constants/imageSizes"
import { useGlobalValue } from "../hooks/useGlobalValue"

const WebinarTile = ({ data, onClick, type = "compact", className }) => {
  const {
    id,
    title,
    subtitle,
    premium_only,
    short_description,
    landscape,
    available_date,
    author,
    author_rels,
    video,
    url,
  } = data

  const { t } = useTranslation()
  const [{ userSubscription }] = useGlobalValue()
  const premiumUser = isSubscriptionValid(userSubscription)

  const handleClick = () => {
    // onClick && onClick(data)
    navigate(`/webinar/${id}`)
  }

  const coverUrl = getImageUrl(landscape, DEFAULT_LANDSCAPE_IMAGE_SIZE)

  const actions = useMemo(() => {
    if (!premiumUser && premium_only) {
      return [
        <Button className="premium-only" type="primary" onClick={handleClick}>
          <span>{t("button:premiumOnly")}</span>
          <img
            src="/assets/images/subscription-premium-white.svg"
            alt="subscription premium"
          />
        </Button>,
      ]
    }

    if (video) {
      return [
        <Button className="green" onClick={handleClick}>
          {t("button:onlyOnSportScience")}
        </Button>,
      ]
    }

    if (url) {
      return [
        <Button className="yellowsp" onClick={handleClick}>
          {t("button:signupForFreeToWebinar")}
        </Button>,
      ]
    }
  }, [data, userSubscription, premium_only, premiumUser])

  return (
    <div className={`webinar-tile ${className}`}>
      <AtsCard
        title={title}
        subtitle={subtitle}
        description={short_description}
        cover={coverUrl}
        actions={actions}
        extra={[
          <span>{formatDate(available_date)}</span>,
          <AuthorList
            authorList={author_rels.map(({ author }) => author)}
            maxToShow={5}
          />,
        ]}
        type={type}
        onClick={handleClick}
      />
    </div>
  )
}

export default WebinarTile
