export const useBrowserDetector = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent
  const userWindow = typeof window === "undefined" ? "SSR" : window
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isIphone = () => Boolean(userAgent.match(/iPhone/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))
  const isSSR = () => Boolean(userAgent.match(/SSR/i))

  const isSmallScreen = () => userWindow && userWindow.innerWidth <= 576

  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows())
  const isDesktop = () => Boolean(!isMobile() && !isSSR())
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
    isSmallScreen,
    isIphone,
  }
}
