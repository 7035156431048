import React, { useEffect, useState } from "react"
import useIntersection from "../hooks/useIntersection"
import { Skeleton } from "antd"

const LazyWrapper = ({ children, loadingIndicator }) => {
  const [element, setElement] = useState(null)
  const inViewport = useIntersection(element, "10px")
  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    if (inViewport && !showContent) {
      setShowContent(true)
    }
  }, [inViewport, element])

  const loadingElement = loadingIndicator || <Skeleton active round />

  return (
    <div ref={div => setElement(div)}>
      {showContent ? children : loadingElement}
    </div>
  )
}

export default LazyWrapper
