import React, { useMemo } from "react"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { LoadingOutlined } from "@ant-design/icons"
import { FREE, ON_DEMAND, SUBSCRIPTION } from "../enums/PricePolicies"
import { getPriceBadge, isSubscriptionValid, isUndefined } from "../utils"
import { useAuth0 } from "@auth0/auth0-react"
import { LAWFUL_SUBSCRIPTION_ROLES } from "../enums/UserRoles"

const typesWithBadge = [
  "ats_courses",
  "ats_videos",
  "ats_certifications",
  "ats_bundles",
  "courses",
  "videos",
  "certifications",
  "bundles",
]

const PURCHASABLE_ITEMS = [
  "ats_certifications",
  "certifications",
  "ats_bundles",
  "bundles",
]

const withTileBadgeCheck = Component => {
  return props => {
    const [{ userSubscription, userInventory, user, isLoadingUser }] =
      useGlobalValue()
    const { isAuthenticated } = useAuth0()
    const { data } = props

    const badge = useMemo(() => {
      if (!typesWithBadge.includes(data.__typename)) {
        return null
      }

      if (isLoadingUser) {
        return <LoadingOutlined />
      }

      const { price_policy, id, __typename } = data.course ? data.course : data

      const ownedBadge = <img src="/assets/images/green-check.svg" alt="" />

      if (price_policy === FREE) {
        if (isAuthenticated) {
          return ownedBadge
        }
      }

      if (
        price_policy === ON_DEMAND ||
        PURCHASABLE_ITEMS.includes(__typename)
      ) {
        if (!isUndefined(userInventory)) {
          if (!isUndefined(userInventory)) {
            if (userInventory.filter(({ item_id }) => id === item_id).length) {
              return ownedBadge
            }
          }
        }
      }

      if (!isUndefined(userSubscription)) {
        if (price_policy === SUBSCRIPTION) {
          if (LAWFUL_SUBSCRIPTION_ROLES.includes(user?.role)) {
            return ownedBadge
          }

          if (isSubscriptionValid(userSubscription)) {
            return ownedBadge
          }
        }
      }

      return getPriceBadge(price_policy)
    }, [userSubscription, userInventory, user, isLoadingUser])

    return <Component {...props} badge={badge} loading={isLoadingUser} />
  }
}

export default withTileBadgeCheck
