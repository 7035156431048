import { useMemo, useState } from "react"
import { devLog, getDominantColorFromImage } from "../utils"
import Color from "color"

export const useImageColorDetector = ({ url, image }) => {
  const [isDark, setIsDark] = useState(false)

  useMemo(() => {
    getDominantColorFromImage({ url, image })
      .then(({ r, g, b }) => {
        const color = Color(`rgb(${r},${g},${b})`)
        const luminosity = color.luminosity()
        devLog({ luminosity })
        setIsDark(luminosity < 0.3)
      })
      .catch(error => {
        console.log({ error })
      })
    // analyze(url)
    //   .then(results => {
    //     const color = Color(results[0].color)
    //     const luminosity = color.luminosity()
    //     console.log({ luminosity })
    //     setIsDark(luminosity < 0.5)
    //   })
    //   .catch(error => {
    //     console.log({ error })
    //   })
  }, [url, image])

  return {
    isDark,
  }
}
