import React from "react"
import it_IT from "antd/es/locale/it_IT"
import en_US from "antd/es/locale/en_US"
import { ConfigProvider } from "antd"
import "antd/dist/antd.dark.less"
import "../style/style.less"
import "../constants/fragments"

import { GlobalProvider } from "../hooks/useGlobalValue"
import { initialState, reducer } from "../reducer"
import ApolloProviderWithAuth0 from "./ApolloProviderWithAuth0"
import { Auth0Provider } from "@auth0/auth0-react"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import moment from "moment"
import "moment/locale/it"
import FirstRun from "./FirstRun"

import "../../wdyr"

import * as ALL_LANGS from "../static/locale"

moment.locale(process.env.GATSBY_LANG)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      it_IT: ALL_LANGS.it_IT,
      en_US: ALL_LANGS.en_US,
    },
    lng: process.env.GATSBY_LANG,
    fallbackLng: "en_US",

    interpolation: {
      escapeValue: false,
    },
  })

const RootWrapper = ({ element }) => {
  const allLocales = {
    it_IT: it_IT,
    en_US: en_US,
  }
  return (
    <ConfigProvider locale={allLocales[process.env.GATSBY_LANG] || it_IT}>
      <GlobalProvider initialState={initialState} reducer={reducer}>
        <Auth0Provider
          domain={process.env.GATSBY_AUTH0_DOMAIN}
          clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
          redirectUri={process.env.GATSBY_AUTH0_REDIRECT_URI}
          audience="hasura"
          cacheLocation="localstorage"
          useRefreshTokens={true}
        >
          <ApolloProviderWithAuth0>
            <FirstRun />
            {element}
          </ApolloProviderWithAuth0>
        </Auth0Provider>
      </GlobalProvider>
    </ConfigProvider>
  )
}

export default RootWrapper
