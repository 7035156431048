exports.it_IT = {
  video: "video",
  "/video": "/video",
  "/category/all": "/tutte-le-categorie",
  category: "categoria",
  "/category": "/categoria",
  "/channel/all": "/tutti-i-canali",
  channel: "canale",
  "/author/all": "/tutti-gli-autori",
  author: "autore",
  "/course/all": "/tutti-i-corsi",
  course: "corso",
  "/course": "/corso",
  "/certification/all": "/tutte-le-certificazioni",
  "/certification": "/certificazione",
  certification: "certificazione",
  exam: "esame",
  "/tos": "/tos",
  "/privacy": "/privacy",
  "/about": "/about",
  "/help": "/help",
  "/help/success": "/help/success",
  ats_videos: "video",
  VIDEO: "video",
  COURSE: "corso",
  AUTHOR: "autore",
  CERTIFICATION: "certificazione",
  CHANNEL: "canale",
  CATEGORY: "categoria",
}

exports.en_US = {
  video: "video",
  "/video": "/video",
  "/category/all": "/all-categories",
  category: "category",
  "/category": "/category",
  "/channel/all": "/all-channels",
  channel: "channel",
  "/author/all": "/all-authors",
  author: "author",
  "/course/all": "/all-courses",
  course: "course",
  "/course": "/course",
  "/certification/all": "/all-certifications",
  "/certification": "/certification",
  certification: "certification",
  exam: "exam",
  "/tos": "/tos",
  "/privacy": "/privacy",
  "/about": "/about",
  "/help": "/help",
  "/help/success": "/help/success",
  ats_videos: "video",
  VIDEO: "video",
  COURSE: "course",
  AUTHOR: "author",
  CERTIFICATION: "certification",
  CHANNEL: "channel",
  CATEGORY: "category",
}
