import React, { useEffect, useState } from "react"
import "./Countdown.less"
import { formatDuration } from "../utils"

const Countdown = ({ duration, size, onExpire }) => {
  const [time, setTime] = useState(duration)
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    const _intervalId = setInterval(() => {
      setTime(prev => Math.max(0, prev - 1000))
    }, 1000)

    setIntervalId(_intervalId)

    return () => {
      clearInterval(_intervalId)
    }
  }, [])

  useEffect(() => {
    if (time <= 0) {
      clearInterval(intervalId)
      onExpire && onExpire()
    }
  }, [time, onExpire, intervalId])

  return (
    <span
      className="count-down"
      style={{ fontSize: size, lineHeight: `${size}px` }}
    >
      {formatDuration(time / 1000)}
    </span>
  )
}

export default Countdown
