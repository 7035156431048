import { gql, useApolloClient } from "@apollo/client"
import { useGlobalValue } from "./useGlobalValue"
import { useAuth0 } from "@auth0/auth0-react"
import fetch from "cross-fetch"
import _ from "lodash"
import { devLog } from "../utils"
import QueryString from "query-string"

const QUERY_FETCH_POLICY = "network-only"

const GET_CURRENT_USER = gql`
  query currentUser($user_id: String!) {
    auth0_users_by_pk(user_id: $user_id) {
      role
      user_id
      last_login
      basic_profile {
        id
        firstname
        lastname
        email
        phone_number
        newsletter
      }
      billing_profile {
        id
        firstname
        lastname
      }
      subscriptions(where: { state: { _eq: "Active" } }) {
        id
        state
        end_at
      }
    }
  }
`

const GET_USER_FAVORITE_QUERY = gql`
  query fetchUserFavorite($item_id: uuid!, $user_id: String!) {
    user_favorites_by_pk(item_id: $item_id, user_id: $user_id) {
      is_favorite
    }
  }
`

const INSERT_USER_VIDEO_ACTION_MUTATION = gql`
  mutation insertVideoAction($data: user_video_actions_insert_input!) {
    insert_user_video_actions_one(object: $data) {
      id
    }
  }
`

const UPSERT_USER_VIDEO_HISTORY_MUTATION = gql`
  mutation upsertVideoHistory($data: [user_video_histories_insert_input!]!) {
    insert_user_video_histories(
      objects: $data
      on_conflict: {
        constraint: user_video_histories_pkey
        update_columns: [time, updated_at]
      }
    ) {
      affected_rows
    }
  }
`

const GET_VIDEO_HISTORY_QUERY = gql`
  query getVideoHistory($video_id: uuid!) {
    user_video_histories(where: { video_id: { _eq: $video_id } }) {
      time
    }
  }
`

const GET_COURSE_VIDEO_HISTORY = gql`
  query getCourseVideoHistory($id: uuid!) {
    courses_by_pk(id: $id) {
      videos {
        video {
          duration
          history {
            time
          }
        }
      }
    }
  }
`

const GET_COURSE_VIDEO_HISTORIES_QUERY = gql`
  query courseVideoHistories($id: uuid!) {
    courses_by_pk(id: $id) {
      videos(
        order_by: {
          video: { history: { updated_at: desc_nulls_last } }
          index: asc
        }
      ) {
        video_id
        video {
          seo {
            slug
          }
        }
      }
    }
  }
`

const REMOVE_PLAYED_RANGE_MUTATION = gql`
  mutation removePlayedRange($sessionId: String) {
    delete_user_video_play_ranges(where: { session_id: { _eq: $sessionId } }) {
      affected_rows
    }
  }
`

const INSERT_PLAYED_RANGE_MUTATION = gql`
  mutation insertVideoPlayedRange(
    $data: [user_video_play_ranges_insert_input!]!
  ) {
    insert_user_video_play_ranges(objects: $data) {
      affected_rows
    }
  }
`

const GET_MOST_VIEWED_VIDEOS_QUERY = gql`
  {
    videos(
      order_by: {
        played_ranges_aggregate: { sum: { duration: desc_nulls_last } }
      }
      limit: 10
    ) {
      id
      course_rel {
        course_id
      }
      played_ranges_aggregate {
        aggregate {
          sum {
            duration
          }
        }
      }
    }
  }
`

const useCommonQueries = () => {
  const client = useApolloClient()
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0()
  const [{}, dispatch] = useGlobalValue()

  const getCurrentUser = async () => {
    devLog({ user })
    const { data } = await client.query({
      query: GET_CURRENT_USER,
      variables: { user_id: user?.sub },
      fetchPolicy: QUERY_FETCH_POLICY,
    })
    return data.auth0_users_by_pk
  }

  const getSignedVideoSourceUrl = async ({ videoId }) => {
    const url = `/.netlify/functions/get-video-signed-url`
    const token = await getAccessTokenSilently()

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ videoId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const data = await response.json()

    const { sourceUrl, error } = data

    if (error) {
      throw new Error(error.code)
    }

    if (response.status !== 200) {
      throw new Error("500")
    }

    return sourceUrl
  }

  const insertVideoPlayedRange = async ({ data, sessionId }) => {
    const { errors: removeErrors } = await client.mutate({
      mutation: REMOVE_PLAYED_RANGE_MUTATION,
      variables: {
        sessionId,
      },
    })

    if (removeErrors) {
      throw removeErrors[0]
    }

    const { errors: insertErrors } = await client.mutate({
      mutation: INSERT_PLAYED_RANGE_MUTATION,
      variables: {
        data,
      },
    })

    if (insertErrors) {
      throw insertErrors[0]
    }

    return true
  }

  const getMostViewedItemList = async () => {
    const { data, errors } = await client.query({
      query: GET_MOST_VIEWED_VIDEOS_QUERY,
    })

    if (errors) {
      throw errors[0]
    }

    const temp = {} // [{itemId: xxx, duration: xxx}]
    data.videos.map(video => {
      temp[video.id] =
        video.played_ranges_aggregate?.aggregate?.sum?.duration ?? 0
      if (video.course_rel) {
        temp[video.course_rel.course_id] = temp[video.course_rel.course_id] ?? 0
        temp[video.course_rel.course_id] +=
          video.played_ranges_aggregate?.aggregate?.sum?.duration ?? 0
      }
    })

    const result = Object.keys(temp).map(key => {
      return {
        id: key,
        duration: temp[key],
      }
    })

    return _.orderBy(result, "duration", "desc")
  }

  const createPurchaseIntent = async (item_type, item_id) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/.netlify/functions/create-purchase-intent", {
      method: "POST",
      body: JSON.stringify({ item_type, item_id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { purchaseId, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    return purchaseId
  }

  const upsertVideoHistory = async ({ videoId, time }) => {
    const { data, errors } = await client.mutate({
      mutation: UPSERT_USER_VIDEO_HISTORY_MUTATION,
      variables: {
        data: {
          time,
          video_id: videoId,
          updated_at: new Date(),
        },
      },
    })

    if (errors) {
      throw errors[0]
    }

    return data
  }

  const logUserSearch = async ({ search }) => {
    throw new Error("not implemented")
  }

  const getMostLike = async () => {
    const response = await fetch("/.netlify/functions/get-most-liked", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })

    const { data, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }

    const result = Object.keys(data).map(key => {
      return {
        id: key,
        count: data[key],
      }
    })

    return _.orderBy(result, "count")
  }

  const logUserVideoAction = async ({
    videoId,
    action,
    currentTime,
    previousTime,
    sessionId,
    value,
  }) => {
    const { data, errors } = await client.mutate({
      mutation: INSERT_USER_VIDEO_ACTION_MUTATION,
      variables: {
        data: {
          action,
          current_time: currentTime,
          video_id: videoId,
          previous_time: previousTime,
          session_id: sessionId,
          value,
          platform: window.navigator.userAgent,
        },
      },
    })

    if (errors) {
      throw errors[0]
    }

    return data
  }

  const redeemCoupon = async ({ couponCode }) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/.netlify/functions/redeem-coupon", {
      method: "POST",
      body: JSON.stringify({ couponCode }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const { error } = await response.json()

    if (response.status !== 200) {
      throw error
    }
    return true
  }

  const addUserPaymentMethod = async data => {
    const token = await getAccessTokenSilently()

    const response = await fetch(
      "/.netlify/functions/braintree-add-payment-method",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    const { paymentMethod, error } = await response.json()

    if (response.status !== 200) {
      throw error
    }
    return paymentMethod
  }

  const toggleUserFavorite = async ({ item_id, item_type, is_favorite }) => {
    const mutation = gql`
      mutation updateUserFavorite($data: user_favorites_insert_input!) {
        insert_user_favorites_one(
          object: $data
          on_conflict: {
            constraint: user_favorites_pkey
            update_columns: is_favorite
          }
        ) {
          is_favorite
        }
      }
    `

    const { data, errors } = await client.mutate({
      mutation,
      variables: {
        data: { item_id, item_type, is_favorite },
      },
    })
    if (errors) {
      throw errors[0]
    }

    return data
  }

  const upsertUserSetting = async ({ key, value }) => {
    const mutation = gql`
      mutation setSignUpData($key: String!, $value: String!) {
        insert_user_settings_one(
          object: { key: $key, value: $value }
          on_conflict: { constraint: user_settings_pkey, update_columns: value }
        ) {
          key
        }
      }
    `

    const { data, errors } = await client.mutate({
      mutation,
      variables: { key, value },
    })
    if (errors) {
      throw errors[0]
    }

    return data
  }

  const getIsFavorite = async itemId => {
    if (!isAuthenticated) {
      return false
    }

    const { data, errors } = await client.query({
      query: GET_USER_FAVORITE_QUERY,
      variables: { user_id: user?.sub, item_id: itemId },
      fetchPolicy: QUERY_FETCH_POLICY,
    })

    if (errors) {
      throw errors[0]
    }

    return data.user_favorites_by_pk?.is_favorite
  }

  const getVideoHistory = async ({ videoId }) => {
    const { data, errors } = await client.query({
      query: GET_VIDEO_HISTORY_QUERY,
      variables: { video_id: videoId },
      fetchPolicy: QUERY_FETCH_POLICY,
    })

    if (errors) {
      throw errors[0]
    }

    return data.user_video_histories[0]?.time ?? 0
  }

  const getCourseProgress = async ({ courseId }) => {
    const { data, errors } = await client.query({
      query: GET_COURSE_VIDEO_HISTORY,
      variables: { id: courseId },
      fetchPolicy: QUERY_FETCH_POLICY,
    })

    if (errors) {
      return 0
    }

    try {
      const videos = data.courses_by_pk.videos
      const totalTime = videos.reduce((accumulator, { video }) => {
        return accumulator + (video?.duration || 0)
      }, 0)

      const totalPlayedTime = videos.reduce((accumulator, { video }) => {
        return accumulator + (video?.history?.time || 0)
      }, 0)

      const progress = (totalPlayedTime / totalTime) * 100

      return progress ?? 0
    } catch (error) {
      devLog({ error })
      return 0
    }
  }

  const getLastWatchVideoIdInCourse = async ({ courseId }) => {
    const { data, errors } = await client.query({
      query: GET_COURSE_VIDEO_HISTORIES_QUERY,
      variables: { id: courseId },
      fetchPolicy: QUERY_FETCH_POLICY,
    })

    if (errors) {
      throw errors[0]
    }

    return data.courses_by_pk?.videos[0]?.video_id
  }

  const getLastWatchVideoSlugInCourse = async ({ courseId }) => {
    const { data, errors } = await client.query({
      query: GET_COURSE_VIDEO_HISTORIES_QUERY,
      variables: { id: courseId },
      fetchPolicy: QUERY_FETCH_POLICY,
    })

    if (errors) {
      throw errors[0]
    }

    return data.courses_by_pk?.videos[0]?.video.seo.slug
  }

  const getHumanCollections = async () => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/.netlify/functions/my-human-content", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const data = await response.json()

    if (response.status !== 200) {
      throw new Error(response.message)
    }

    return data
  }

  const sendEmail = async ({ firstname, lastname, email, message }) => {
    const token = await getAccessTokenSilently()

    const response = await fetch("/.netlify/functions/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ firstname, lastname, email, message }),
    })

    const data = await response.json()

    if (response.status !== 200) {
      throw new Error(response.message)
    }

    return data
  }

  const getDownloadUrl = async (s3Key, options = {}) => {
    const token = await getAccessTokenSilently()

    const params = { ...options, key: s3Key, token }
    const search = QueryString.stringify(params)
    return `/.netlify/functions/get-download-url?${search}`
  }

  return {
    getCurrentUser,
    getSignedVideoSourceUrl,
    logUserSearch,
    logUserVideoAction,
    toggleUserFavorite,
    getIsFavorite,
    createPurchaseIntent,
    getMostLike,
    upsertVideoHistory,
    getVideoHistory,
    getCourseProgress,
    getLastWatchVideoIdInCourse,
    getLastWatchVideoSlugInCourse,
    insertVideoPlayedRange,
    getMostViewedItemList,
    getHumanCollections,
    sendEmail,
    getDownloadUrl,
    redeemCoupon,
    upsertUserSetting,
  }
}

export default useCommonQueries
