import React from "react"
import "react-quill/dist/quill.bubble.css"

const ReadOnlyQuill = props => {
  const ReactQuill = require("react-quill")
  const value = props.value ?? ""

  return <ReactQuill {...props} value={value} theme={null} readOnly={true} />
}

export default ReadOnlyQuill
