import { useEffect, useState } from "react"

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    if (!element) {
      return
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting)
        if (entry.isIntersecting) {
          observer.unobserve(element)
        }
      },
      { rootMargin }
    )

    element && observer.observe(element)

    return () => observer.unobserve(element)
  }, [element])

  return isVisible
}

export default useIntersection
