import React from "react"
import "./ComingSoon.less"
import ContainerWithPadding from "./ContainerWithPadding"

const ComingSoon = () => {
  return (
    <ContainerWithPadding align={"center"} size={"medium"}>
      <h1>Prossimamente disponibile</h1>
    </ContainerWithPadding>
  )
}

export default ComingSoon
