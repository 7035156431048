import button from "./button.json"
import comingSoon from "./comingSoon.json"
import cookieConsent from "./cookieConsent.json"
import error from "./error.json"
import footer from "./footer.json"
import form from "./form.json"
import generic from "./generic.json"
import invoice from "./invoice.json"
import label from "./label.json"
import landing from "./landing.json"
import menu from "./menu.json"
import message from "./message.json"
import notification from "./notification.json"
import plan from "./plan.json"
import seo from "./seo.json"
import signUp from "./signUp.json"
import title from "./title.json"
import warning from "./warning.json"
import myLibrary from "./myLibrary.json"

const it_IT = {
  button,
  comingSoon,
  cookieConsent,
  error,
  footer,
  form,
  generic,
  invoice,
  label,
  landing,
  menu,
  message,
  notification,
  plan,
  seo,
  signUp,
  title,
  warning,
  myLibrary,
}

export default it_IT
