import { useEffect } from "react"

const useScript = ({
  url,
  async = true,
  onLoad,
  onError,
  scriptType = "application/javascript",
}) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = url
    script.async = async
    script.onload = () => {
      if (onLoad) onLoad()
    }
    script.type = scriptType
    script.onerror = onError

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export default useScript
