import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"

import { getMainDefinition } from "@apollo/client/utilities"
import { WebSocketLink } from "@apollo/client/link/ws"
import fetch from "cross-fetch"
import { setContext } from "@apollo/client/link/context"
import * as ws from "ws"
import { useBrowserDetector } from "../hooks/useBrowserDetector"
import { devLog } from "../utils"

const ApolloProviderWithAuth0 = ({ children }) => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0()
  const [linkError, setLinkError] = useState(null)
  const [pageReady, setPageReady] = useState(false)

  const { isSSR } = useBrowserDetector()

  useEffect(() => {
    setPageReady(true)
  }, [])

  // if (isLoading) {
  //   return <LoadingScreen />
  // }
  //
  // if (!pageReady) {
  //   return <LoadingScreen />
  // }
  //
  // if (error) {
  //   return <ErrorScreen error={error} />
  // }

  const httpLink = createHttpLink({
    uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
    fetch,
  })

  const createWsHeaders = async () => {
    const token = await getAccessTokenSilently()

    devLog({ token })

    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        "x-hasura-role": token ? "user" : "anonymous",
      },
    }
  }

  let wsOptions = {
    uri: process.env.GATSBY_GRAPHQL_WSS_ENDPOINT,
    //uri: 'wss://hasura-ats.cloudstar.io/v1/graphql',
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: createWsHeaders,
    },
  }

  if (isSSR()) {
    wsOptions = {
      ...wsOptions,
      webSocketImpl: ws,
      webSocketImplOptions: {
        connectionParams: createWsHeaders,
      },
    }
  }

  const wsLink = new WebSocketLink(wsOptions)

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      )
    },
    wsLink,
    httpLink
  )

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getAccessTokenSilently()

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "x-hasura-role": token ? "user" : "anonymous",
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )

      const errorCode = graphQLErrors[0]?.extensions?.code
      if (errorCode === "access-denied") {
        setLinkError(new Error(errorCode))
      }
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  })

  const apolloClient = new ApolloClient({
    link: errorLink.concat(authLink.concat(splitLink)),
    //link: wsLink,
    cache: new InMemoryCache(),
  })

  if (!apolloClient) {
    return null
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloProviderWithAuth0
